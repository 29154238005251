import { clientInit } from '@jill64/sentry-sveltekit-cloudflare';
import { env } from '$env/dynamic/public';
import { initPostHog } from '$lib/utils/analytics';

if (typeof window !== 'undefined') {
	try {
		const apiKey = env.PUBLIC_POSTHOG_API_KEY;
		const apiHost = env.PUBLIC_POSTHOG_HOST;
		initPostHog(apiKey, apiHost);
	} catch (error) {
		console.error('Error initializing PostHog', error);
	}
}

const onError = clientInit(env.PUBLIC_SENTRY_DSN, {
	sentryOptions: {
		tunnel: '/sentry'
	}
});

export const handleError = onError();
