import * as client_hooks from '../../../src/hooks.client.ts';

export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38')
];

export const server_loads = [2,6];

export const dictionary = {
		"/(auth)": [~7,[2]],
		"/(auth)/account": [21,[2]],
		"/(no-auth)/(glowing-bg)/account/reset-password": [27,[5]],
		"/(no-auth)/(glowing-bg)/account/reset-password/confirm": [28,[5]],
		"/(no-auth)/(glowing-bg)/account/sign-in": [~29,[5]],
		"/(no-auth)/(glowing-bg)/account/sign-up": [30,[5]],
		"/(no-auth)/(glowing-bg)/account/verify-email": [31,[5]],
		"/(auth)/(admin)/admin/auto-apply": [8,[2,3]],
		"/(auth)/(admin)/admin/cloud/api-docs": [9,[2,3]],
		"/(auth)/(admin)/admin/cloud/home": [10,[2,3]],
		"/(auth)/(admin)/admin/cloud/tasks": [11,[2,3]],
		"/(auth)/(admin)/admin/users": [12,[2,3]],
		"/(auth)/(admin)/admin/vacancies": [13,[2,3]],
		"/(auth)/contact": [22,[2]],
		"/(no-auth)/(glowing-bg)/demo": [32,[5]],
		"/(auth)/find-jobs": [23,[2]],
		"/(auth)/(create-ghost)/for-you": [14,[2]],
		"/(auth)/(create-ghost)/for-you/start": [~15,[2]],
		"/(auth)/(create-ghost)/for-you/start/(progress-bar)/preferences": [16,[2,4]],
		"/(auth)/(create-ghost)/for-you/start/(progress-bar)/resume": [17,[2,4]],
		"/(auth)/(create-ghost)/for-you/start/(progress-bar)/review": [18,[2,4]],
		"/(auth)/(create-ghost)/jobs": [19,[2]],
		"/(auth)/(create-ghost)/jobs/[slug]": [20,[2]],
		"/(no-auth)/onboarding": [33,[6]],
		"/(no-auth)/onboarding/complete": [35,[6]],
		"/(no-auth)/onboarding/[stepId]": [34,[6]],
		"/(no-auth)/quickstart": [36],
		"/(auth)/resume": [24,[2]],
		"/(auth)/resume/new": [26,[2]],
		"/(auth)/resume/[id]": [25,[2]],
		"/(no-auth)/sentry/test": [37],
		"/(no-auth)/welcome": [38]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
};

export { default as root } from '../root.svelte';